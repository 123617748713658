import React from "react"
import 'react-accessible-accordion/dist/fancy-example.css'
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from 'react-accessible-accordion'

import BanapiPaperPdf from "../assets/apresentacoes-2022/Banapi-Paper.pdf";
import BiomanPdf from "../assets/apresentacoes-2022/BIOMAN.pdf";
import CousinSPdf from "../assets/apresentacoes-2022/Cousins.pdf";
import EspinosaPdf from "../assets/apresentacoes-2022/Espinosa.pdf";
import EulerPdf from "../assets/apresentacoes-2022/Euler.pdf";
import ApolloZPdf from "../assets/apresentacoes-2022/Appollo-Z.pdf";
import QuemEraPdf from "../assets/apresentacoes-2022/Quem-eRa.pdf";
import SigmaPdf from "../assets/apresentacoes-2022/Choque-no-Foco-Final.pdf";
import YPotiPdf from "../assets/apresentacoes-2022/Y-Poti.pdf";


import BanapiPaperVideo from "@/components/BanapiPaperVideo"
import BiomamVideo from "@/components/BiomanVideo"
import CousinsVideo from "@/components/CousinsVideo"
import EspinosaVideo from "@/components/EspinosaVideo"
import EulerVideo from "@/components/EulerVideo"
import ApollozVideo from "@/components/ApollozVideo"
import QuemEraVideo from "@/components/QuemEraVideo"
import ChoqueNoFocoVideo from "@/components/ChoqueNoFocoVideo"
import YPotiVideo from "@/components/YPotiVideo"

const ProjetosFinalistas2021 = ({}) => {
  return (
    <div style={{ margin: '56px 0' }}>
      <Accordion allowZeroExpanded>
			  <AccordionItem uuid>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div>
                  Literar
                  <p className="projetos-p">Líder: Andressa Florêncio Nunes</p>
                </div>
                <div className="accordion-arrow" style={{ marginLeft: 'auto' }} />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="accordion-p">Democratizar o acesso à educação e leitura por meio da construção de mini bibliotecas feitas de materiais recicláveis.</p>
            {/* <div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
              <div className="ML_32 MB-56">
                <a className="blue_btn PD-pink-1" href='' target='_blank'>
                  <span>Conheça o projeto (PDF)</span>
                </a>
              </div>
              <div>
                <BanapiPaperVideo />
              </div>
            </div> */}
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div>
                Kit Culturando 
                  <p className="projetos-p">Líder: Anny Bheatriz de Lima Medeiros</p>
                </div>
                <div className="accordion-arrow" style={{ marginLeft: 'auto' }} />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="accordion-p">Kit educacional que visa promover um conhecimento enriquecedor sobre a cultura dos nossos ancestrais em sala de aula por meio de ferramentas didáticas e oficinas que trabalham a autonomia junto das habilidades artísticas e científicas dos alunos e professores.</p>
            {/* <div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
              <div className="ML_32 MB-56">
                <a className="blue_btn PD-pink-1" href={BiomanPdf} target='_blank'>
                  <span>Conheça o projeto (PDF)</span>
                </a>
              </div>
              <div>
                <BiomamVideo />
              </div>
            </div> */}
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div>
                CaatingaCraft
                  <p className="projetos-p">Líder: Giovanni Herculano Cazé</p>
                </div>
                <div className="accordion-arrow" style={{ marginLeft: 'auto' }} />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="accordion-p">Jogo educativo que visa ensinar sobre a Caatinga, fornecer informações precisas, criar desafios educativos e permitir a interação com especialistas. Busca-se transformar o aprendizado em ação concreta para a preservação.</p>
            {/* <div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
              <div className="ML_32 MB-56">
                <a className="blue_btn PD-pink-1" href={CousinSPdf} target='_blank'>
                  <span>Conheça o projeto (PDF)</span>
                </a>
              </div>
              <div>
                <CousinsVideo />
              </div>
            </div> */}
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div>
                MentorHub
                  <p className="projetos-p">Líder: Guilherme Lacerda Protasio</p>
                </div>
                <div className="accordion-arrow" style={{ marginLeft: 'auto' }} />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="accordion-p">Plataforma online de assessoria educacional, na qual graduandos se voluntariam, para serem assessores de estudantes do Ensino Médio, a fim de facilitarem e democratizarem o processo de aprendizagem para os vestibulares.</p>
            {/* <div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
              <div className="ML_32 MB-56">
                <a className="blue_btn PD-pink-1" href={EspinosaPdf} target='_blank'>
                  <span>Conheça o projeto (PDF)</span>
                </a>
              </div>
              <div>
                <EspinosaVideo />
              </div>
            </div> */}
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div>
                Documentário Abismo 
                  <p className="projetos-p">Líder: Helena Irineu Burguer</p>
                </div>
                <div className="accordion-arrow" style={{ marginLeft: 'auto' }} />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="accordion-p">Documentário que lidera uma investigação para compreender e expor os motivos que impedem um avanço do sistema educacional brasileiro.</p>
            {/* <div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
              <div className="ML_32 MB-56">
                <a className="blue_btn PD-pink-1" href={EulerPdf} target='_blank'>
                  <span>Conheça o projeto (PDF)</span>
                </a>
              </div>
              <div>
                <EulerVideo />
              </div>
            </div> */}
          </AccordionItemPanel>
        </AccordionItem>
        {/* <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div>
                Azevision
                  <p className="projetos-p">Líder: José Antônio Eleutério</p>
                </div>
                <div className="accordion-arrow" style={{ marginLeft: 'auto' }} />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="accordion-p">Estação de estudos e monitoramento de meteoros, com o objetivo de ​​fomentar a pesquisa científica em astronomia no ensino básico.</p>
            <div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
              <div className="ML_32 MB-56">
                <a className="blue_btn PD-pink-1" href={ApolloZPdf} target='_blank'>
                  <span>Conheça o projeto (PDF)</span>
                </a>
              </div>
              <div>
                <ApollozVideo />
              </div>
            </div>
          </AccordionItemPanel>
        </AccordionItem> */}
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div>
                RAESHI 
                  <p className="projetos-p">Líder: Mariana Junqueira Lira </p>
                </div>
                <div className="accordion-arrow" style={{ marginLeft: 'auto' }} />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="accordion-p">Kit de robótica acessível através de um Shield no raspberry pi para o ensino da robótica e do pensamento computacional em escolas públicas.</p>
            {/* <div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
              <div className="ML_32 MB-56">
                <a className="blue_btn PD-pink-1" href={QuemEraPdf} target='_blank'>
                  <span>Conheça o projeto (PDF)</span>
                </a>
              </div>
              <div>
                <QuemEraVideo />
              </div>
            </div> */}
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div>
                Heróis dos Direitos Humanos
                  <p className="projetos-p">Líder: Maria Vitória Souza Tenório</p>
                </div>
                <div className="accordion-arrow" style={{ marginLeft: 'auto' }} />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="accordion-p">Jogo educativo digital que combina elementos de aventura, narrativa e desafios para ensinar sobre direitos humanos e destacar histórias inspiradoras de personalidades de destaque. </p>
            {/* <div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
              <div className="ML_32 MB-56">
                <a className="blue_btn PD-pink-1" href={SigmaPdf} target='_blank'>
                  <span>Conheça o projeto (PDF)</span>
                </a>
              </div>
              <div>
                <ChoqueNoFocoVideo />
              </div>
            </div> */}
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div>
                Lendas Matemáticas 
                  <p className="projetos-p">Líder: Sofia Freitas da Silva Gonçalves de Alcântara</p>
                </div>
                <div className="accordion-arrow" style={{ marginLeft: 'auto' }} />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="accordion-p">Jogos de cartas lúdico que visa estimular o aprendizado matemático ao preencher lacunas e vencer preconceitos sobre a matemática básica.</p>
            {/* <div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
              <div className="ML_32 MB-56">
                <a className="blue_btn PD-pink-1" href={YPotiPdf} target='_blank'>
                  <span>Conheça o projeto (PDF)</span>
                </a>
              </div>
              <div>
                <YPotiVideo />
              </div>
            </div> */}
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div>
                IQuality 
                  <p className="projetos-p">Líder: Mathias Bolado </p>
                </div>
                <div className="accordion-arrow" style={{ marginLeft: 'auto' }} />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="accordion-p">Programa educacional que facilita a identificação e o desenvolvimento dos diferentes tipos de inteligência nos alunos. </p>
            {/* <div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
              <div className="ML_32 MB-56">
                <a className="blue_btn PD-pink-1" href={YPotiPdf} target='_blank'>
                  <span>Conheça o projeto (PDF)</span>
                </a>
              </div>
              <div>
                <YPotiVideo />
              </div>
            </div> */}
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
                <div>
                Arandu Ánga  
                  <p className="projetos-p">Líder: Milena Gerhardt Da Silva</p>
                </div>
                <div className="accordion-arrow" style={{ marginLeft: 'auto' }} />
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="accordion-p">Criação de conteúdo educativo voltado para a valorização da cultura do Paraguai.</p>
            {/* <div className="content-project btn-guia" style={{ margin: "56px 0", display: 'flex' }}>
              <div className="ML_32 MB-56">
                <a className="blue_btn PD-pink-1" href={YPotiPdf} target='_blank'>
                  <span>Conheça o projeto (PDF)</span>
                </a>
              </div>
              <div>
                <YPotiVideo />
              </div>
            </div> */}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default ProjetosFinalistas2021;
