import React from "react"
import ProjetosFinalistas2023 from '@/components/ProjetosFinalistas2023'

const Finalistas = ({ title }) => {
  return (
    <section className="pageBanner" style={{ background: '#FAFAFA' }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="banner_content">
              <h2 style={{ color: '#026FC2', marginBottom: 56 }}>{title}</h2>
              <h4 style={{ color: '#212121' }}>Conheça os 7 projetos finalistas de 2023!</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <ProjetosFinalistas2023 />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Finalistas;